@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  
}

.container{
  max-width: 2560px !important;
}

body {
  font-family:'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x :hidden ;
}
 
/*Overflow */
@media (max-width: 768px) {
  body {
    overflow-x: hidden;
  }
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.navbar-light{
  background-color: #F5F5F5;
}

.main-hover:hover {
  background-position: 0;

}
.main-hover:hover::before {
  width: 100%;
}
.main-hover {
  background-image: linear-gradient(
    to right,
    #f00, 
    #f00 50%,
    hsl(29, 100%, 50%) 50% 
  );
  background-size: 200% 100%;
  background-position: -100%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 0.3s ease-in-out;
}
.text-New{
  color:hsl(29, 100%, 50%);
}

::-webkit-scrollbar{
  width: 0;
}

