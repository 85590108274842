Copy code
.plan-card {
  margin: 20px 0;
}

.plan-card .card {
  display: flex;
  flex-direction: row;
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  height: 180px; 
}

.plan-card .card img {
  width: 100%;
  height: 180px; 
  /* object-fit: cover; */
  transition: transform 0.4s ease;
}
.card-title {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 20px;
}

.detailsP{
  font-size: 16px;
}

.plan-card .card img:hover {
  transform: scale(1.1);
}

.plan-card .card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
}

.button-group {
  display: flex;
  justify-content: flex-start;
}

.button-group .Btn {
  margin-right: 10px;
  padding: 10px 20px; /* Adjust padding as needed */
  font-size: 14px; /* Adjust font size for readability */
  text-align: center; /* Center-align text */
  white-space: nowrap; /* Ensure text does not wrap */
  overflow: hidden; /* Hide overflowed text */
  text-overflow: ellipsis; /* Add ellipsis for overflowed text */
}
.itinerary {
  position: relative;
  padding-left: 40px;
}

.itinerary::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 20px; /* Align the vertical line */
  width: 2px;
  background: hsl(29, 100%, 50%);
}

.itinerary-item {
  position: relative;
  margin-bottom: 20px;
  padding-left: 60px; /* Ensure items align properly */
}

.itinerary-point {
  position: absolute;
  left: -25px; /* Align the dot to the vertical line */
  top: 5px;
  width: 12px;
  height: 12px;
  background: hsl(29, 100%, 50%);
  border-radius: 50%;
}

.itinerary-day {
  font-weight: bold;
  color: hsl(29, 100%, 50%) !important;
  position: absolute;
  left: -20px; /* Adjust to place the day text next to the dot */
  top: 0;
}

.itinerary-content {
  padding-left: 40px; /* Align content properly */
}



.custom-titles {
  font-size: 1.5rem; /* Default font size */
  font-weight: bold;
}

@media (min-width: 768px) {
  .custom-titles {
    font-size: 2.5rem; /* Larger font size for tablets and desktops */
  }
}


@media (max-width: 991px) {
  .plan-card .card {
    flex-direction: column;
    height: auto; 
  }
  
  .plan-card .card img {
    width: 100%;
    height: 280px;
    
    
  }
  
  .plan-card .card-body {
    padding: 15px;
  }

  .button-group {
    flex-direction: column; /* Stack buttons vertically on mobile */
    align-items: center; /* Center-align buttons */
  }

  .button-group .Btn {
    width: 100%; /* Make buttons full width on mobile */
    margin: 5px 0; /* Adjust margin for vertical stacking */
    padding: 12px; /* Adjust padding for mobile */
    font-size: 16px; /* Adjust font size for mobile screens */
  }

  .itinerary {
    padding-left: 0;
  }

  .itinerary::before {
    left: 0;
  }

  .itinerary-point {
    left: -5px;
  }

  .itinerary-day {
    left: -120px;
  }
}
@media (max-width: 1300px) and (min-width: 768px) {
  .plan-card .card {
    flex-direction: column;
    /* height: auto;  */
  }

  .plan-card .card img {
    width: 100%;
  }

  .plan-card .card-body {
    padding: 20px; /* Adjust padding for tablets */
  }
  .button-group .Btn {
   
    font-size: 11px; /* Adjust font size for tablet screens */
  }
}