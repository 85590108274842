.body {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; 
  }
  
  .Card
  {
      border: 2px solid;
      width: 300px;
      height: 400px;
  
  }
  
  .card .text-primary {
    font-size: 2rem; 
  }
  
  .user {
    width: auto; 
    border: none; 
    margin: 10px auto;
    text-align: center; 
    font-size: 1.2rem; 
  }
  
  .count {
    width: auto;
    margin: 10px auto;
    text-align: center; 
    font-size: 1.2rem; 
  }
  
  
  
  /* Responsive styles */
  @media (max-width: 576px) {
    .card {
      max-width: 100%; 
      padding: 15px;
    }
  
    .text-primary {
      font-size: 1.5rem; 
    }
  
    .fw-bold {
      font-size: 1rem;
    }
  }
  


