.faq-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  overflow: hidden; /* Prevent overflow */
}

.faq-item,
.faq-comment-box-container {
  max-width: 100%;
  margin-top: 10px;
}

.faq-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #8E7E50;
  color: #fff;
  cursor: pointer;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.faq-icon {
  font-size: 30px;
}



.faq-content {
  padding: 15px;
  background-color: #fff;
  color: #000;
  border: 1px solid #ddd;
  border-top: none;
  border-radius: 0 0 5px 5px;
}

.faq-comment-box-container {
  padding: 0 10px;
}

.faq-comment-box {
  width: 100%;
  min-height: 100px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  resize: none;
}

.faq-submit-button {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.see-more-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.see-more-button {
  border: none;
  background-color: transparent;
  cursor: pointer;
  text-decoration: underline;
  color: #000;
  font-weight: bold;
  transition: background-color 0.2s, color 0.2s;
}

.see-more-button:active {
  background-color: #ccc;
  color: #333;
}

.see-more-button:focus {
  outline: none;
}

form {
  padding: 0;
}


.faq-comment-box::placeholder {
  color: #999;
  opacity: 1;
}


