.upi, .det, .Name {
  border: 1px solid rgb(192, 183, 183);
  border-radius: 3px;
  transition: all 0.3s;
}

.upi img {
  height: auto;
  width: 100%;
  max-width: 350px;
}

.upi:hover, .det:hover, .Name:hover {
  box-shadow: 5px 5px 50px rgb(130, 129, 129);
}
