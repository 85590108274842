footer {
  width: 100%;
}

.img-fluid {
  margin-right: 10px;
}

.text-secondary {
  color: #C7C7C7 !important;
}

.new-p {
  font-size: 0.9rem;
}

.bg-black {
  background-color: #101010 !important;
}

.logo-head {
  font-size: 20px;
}

/* Adjust margin between sections */
@media (min-width: 768px) {
  .col-lg-4 {
    margin-bottom: 20px;
  }
}

@media (max-width: 768px) {
  .col-lg-4 {
    text-align: center;
    margin-bottom: 20px; /* Adjust margin for smaller screens */
  }
}

@media (max-width: 768px) {
  .col-md-4,
  .col-md-6,
  .col-md-2 {
    text-align: center;
  }

  .col-md-4 {
    order: 1;
  }

  .col-md-6 {
    order: 3;
  }

  .col-md-2 {
    order: 2;
  }
}

footer .container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media (min-width: 576px) {
  footer .container {
    justify-content: center;
  }

  .col-md-4 {
    order: 1;
    text-align: left;
  }

  .col-md-6 {
    order: 2;
    text-align: center;
  }

  .col-md-2 {
    order: 3;
    text-align: right;
  }
}
