#floating-button {
    position: fixed;
    right: 10px;
    bottom: 10px;
    width: 50px;
    height: 50px;
    background-color: #8E7E50;
    border-radius: 50%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: white;
    font-size: 24px;
    z-index: 10;
  }
  
  #pop-up {
    position: fixed;
    right: 10px;
    bottom: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s, visibility 0.3s;
    z-index: 999;
  }
  
  #toggle-icon {
    transition: transform 0.3s;
  }
  
  #toggle-icon.rotated {
    transform: rotate(180deg);
  }
  
  #pop-up.visible {
    opacity: 1;
    visibility: visible;
  }
  
  .contact-icon {
    width: 50px;
    height: 50px;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: none;
    font-size: 20px;
  }
  
  #phone-icon {
    background-color: #25d366;
    color: white;
  }
  
  #whatsapp-icon {
    background-color: #25d366;
    color: white;
  }
  
  #email-icon {
    background-color: #ff4b4b;
    color: white;
  }
  
  .contact-icon:hover {
    opacity: 0.8;
  }
  