.inquiry-form-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1200;
  }
  
  .inquiry-form {
    background: #f9f9f9;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    width: 30%;
    max-width: 500px;
    text-align: center;
    position: relative;
  }
  
  .inquiry-form h2 {
    margin-bottom: 10px;
  }
  
  .inquiry-form label {
    display: block;
    margin-bottom: 5px;
    text-align: left;
  }
  
  .inquiry-form input,
  .inquiry-form textarea {
    width: calc(100% - 10px);
    padding: 5px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    
  }
  
  .inquiry-form button[type="submit"] {
    background: #8E7E50;
    color: white;
    border: none;
    padding: 8px 15px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    color: #000;
  }
  
  .close-button:hover {
    color: red;
  }
  
  @media (max-width: 768px) {
    .inquiry-form {
      width: 75%;
      padding: 5px;
    }
  
    .inquiry-form input,
    .inquiry-form textarea {
      width: calc(100% - 10px);
      padding: 5px;
    }
  }
  
  @media (max-width: 1024px){
    .inquiry-form {
      width: 95%;
      padding: 5px;
    }
  }
  