.why-choose-us {
  padding: 20px;
  text-align: center;
}

.section-heading {
  font-size: 2em;
  margin-bottom: 20px;
}

.cards {
    /* width: 240px; */
  height: 260px;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  margin: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
}

.cards:hover{
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.4);
    
}

.texts {
  font-size: 22px;
  padding-top: 8px;
  font-weight: bold;
}

.ptext {
  font-size: 15px;
  text-align: justify;
  padding-top: 20px !important;
  font-weight: 400;
}
.bg-color1 {
    background-color: 
    #77755d;
    color: #fff;
    
  }
  
  .bg-color2 {
    background-color: #154c79;
    color: #fff;
  }
  
