:root {
  --navbar-height: 40px;
}

.new-sec {
  position: relative;
  overflow: hidden;
  height: calc(82vh - var(--navbar-height));
}

.slide {
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: nowrap;
  height: 100%;
}

.slide .item {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 1.5s ease-in-out, transform 1.5s ease-in-out;
}

.slide .item.slide-animation {
  opacity: 1;
  z-index: 1;
}

.slide .item:not(.slide-animation) {
  z-index: 0;
}

.slide .item img {
  width: 100%;
  height: 100%;
  /* object-fit: cover; */
  transition: transform 4s ease-in-out;
}
.controls {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  justify-content: center;
  align-items: center;
  width: 50%;
  position: absolute;
  bottom: 200px;
  left: -100%; /* Start completely off-screen to the left */
  z-index: 1000;
  transform: skewX(-20deg); /* Subtle skew for modern look */
  background: rgba(0, 0, 0, 0.4); /* Semi-transparent dark background */
  padding: 20px 15px;
  border-right: 4px solid white;
  border-radius: 8px;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.4); /* Soft shadow for depth */
  animation: slide-in-left ease-in-out 2s forwards; /* Slide in from the left */
}

@keyframes slide-in-left {
  0% {
    left: -100%; /* Start off-screen to the left */
    opacity: 0;
  }
  100% {
    left: 5%; /* End at the desired position */
    opacity: 1;
  }
}

.custom,
.contact {
  width: 90%; /* Ensure uniform button width */
  text-align: center;
  color: white;
  text-decoration: none;
  padding: 12px;
  margin: 8px 0; /* Maintain vertical spacing */
  background: linear-gradient(
    135deg,
    #f6d365,
    #fda085
  ); /* Vibrant yellow-orange gradient */
  border-radius: 5px;
  transition: all 0.3s ease-in-out; /* Smooth hover */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3); /* Add subtle shadow for buttons */
}

.custom:hover {
  background: #ffcd38; /* Bright yellow for hover */
  color: #f77f00; /* Deep orange for text */
  transform: scale(1.05); /* Slight zoom */
  box-shadow: 0 4px 10px rgba(255, 205, 56, 0.5); /* Yellow shadow */
}

.contact:hover {
  background: #f77f00; /* Bright orange for hover */
  color: white; /* Keep text white */
  transform: scale(1.05); /* Slight zoom */
  box-shadow: 0 4px 10px rgba(247, 127, 0, 0.5); /* Orange shadow */
}

#contact {
  background-color: rgb(82, 81, 80);
}
#contact:hover {
  background-color: rgb(52, 50, 50, 0.6);
}

/* @keyframes slide {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0px);
  }
} */

/* .contact{
  border: none ;
  border-top: 1px solid white;
  border-bottom:4px solid white ;
  padding: 8px;
  background-color: rgb(136, 131, 131);
  color: white;
  opacity: 1;
  animation: slide2 alternate 3s;
}

@keyframes slide2 {
  0%{
    transform: translateX(-100%);
  }
  100%{
  transform:  translateX(0px);
  }
  } */

@keyframes fadezoom {
  0% {
    transform: scale(1.2);
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  100% {
    transform: scale(1.2);
    opacity: 1;
  }
}

.slide-animation img {
  transform: scale(1.2);
}
@media (max-width: 463px) {
  .custom,
  .contact {
    width: 90%;
  }

  .new-sec {
    height: calc(67vh - var(--navbar-height));
  }
  .controls {
    
    bottom: 180px;
   
  }
}

@media (min-width: 768px) and (max-width: 1024px){
  .new-sec {
    height: calc(50vh - var(--navbar-height));
  }
  .controls {
    width: 35%;
    bottom: 1000px;
  }
  .custom,
.contact {
  font-size:40px;
}
}
