.location-info {
  box-shadow: 0px 0px 5px 0px rgb(198, 198, 246);
  border-radius: 5px;
  /* border: 2px solid black; */
  /* display: flex; */
  /* transition: 2s; */
}

.card-body {
  display: flex;
  flex-direction: column;
  justify-content: normal;
}

.custom-checkbox .form-check-input:checked {
  background-color: #8e7e50;
  /* Change to your desired color */
  border-color: #8e7e50;
  /* Change to your desired color */
}

.custom-checkbox .form-check-input {
  border: 1px solid;
  /* color: #333; Change to your desired label color */
}

.location-image {
  /* border: 2px solid green; */
  width: 70px;
  height: 70px;
  /* border-radius :50%;  */
  transition: all 0.3s;
}

.location-image:hover {
  scale: 1.1;
}


.desc {
  display: flex;
  opacity: 0;
  transform: translateY(-20px);
  /* Initial position */
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  animation: fadeInUp 1s forwards;
}

.desc.show {
  opacity: 1;
  transform: translateY(0);
  /* Final position */
}

.view-more-hover {
  transition: text-decoration 0.3s ease;
}

.view-more-hover:hover {
  text-decoration: underline;
}


@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(-20px);
    /* Start from above */
  }

  to {
    opacity: 1;
    transform: translateY(0);
    /* End at normal position */
  }
}



@media (max-width: 767px) {
  .tour-card-title {
    text-align: center;
  }

  .tour-checkbox {
    text-align: left;
  }

  .desc {
    /* border: 2px solid; */
    display: block;
    padding: 0px 10px;
    font-size: 13px;
  }

  .location-image {
    width: 50%;
    height: 50%;
    margin: 10px auto;
  }
}