@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');


header {
    border-bottom: 1px solid black;
  
  }
  
  .navbar-brand {
    display: flex;
    align-items: center;
  }
  
  .navbar-brand h3 {
    font-size: 26px;
    font-weight: bold;
    white-space: nowrap;
  }

  
  
  .navbar-nav .nav-link {
    position: relative;
    display: inline-block;
    
    /* padding-bottom: 10px; */
    margin: 0 5px;
    text-decoration: none;
    color: #000;
    transition: transform 0.3s ease;
    white-space: nowrap; /* Prevent text from wrapping */
    /* border: 2px solid; */
  }

  .navbar-nav .nav-link:not(.custom-tour)::after {
    content: '';
    display: block;
    height: 3px;
    background: transparent ;
    border-radius:20px ;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    
  }
  @keyframes pop {
    0%, 100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.08);
    }
  }
  
  .custom-tour {
    background-color: rgb(252, 151, 0);
    border-radius: 20px;
    font-weight: bolder;
    color: #fff !important;
    cursor: pointer;
    transition: background-color 0.3s ease;
    animation: pop 2s infinite ease-in-out;
  }
  
  .custom-tour:hover {
    background-color: rgb(131, 172, 19);
    animation: none;
  }
  
  .custom-tour::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    /* background-color: rgba(255, 255, 255, 0.2); */
    transition: background-color 0.3s ease;
  }
  

  
  
  
  
  
  .navbar-nav .nav-link:hover:not(.custom-tour) {
    transform: scale(1.1);
  }
  
  .navbar-nav .nav-link:hover:not(.custom-tour)::after {
    background: #f00;
  }
  
  .dropdown-menu a:hover {
    background-color: rgba(240, 240, 240, 0.9);
  }
 


  /* Media query to handle small screens */
  @media (max-width: 1024px) {
    .navbar-brand h3{
      font-size: 25px !important;
      font-weight: 700 !important;
      font-family: Roboto, sans-serif !important; 
    }
    
    .navbar-nav {
      text-align: center;
      width: 100%;
    }
  
    .navbar-nav .nav-item {
      width: 100%;
      margin: 0;
      align-items: center;
      /* justify-content: center; */
      
    }
    
  
    .scrollable-text {
      max-height: 550px;
     
  }
    .navbar-nav .nav-link {
      margin: 5px 0;
    }
    .custom-tour{
      background-color: rgb(252, 151, 0) !important;
      color: #fff;
      
    }
    .custom-tour:hover {
      background-color: rgb(131, 172, 19) !important;
      animation: none;
    }
  }
  
  /* Ensure proper styles on very small screens (320px) */
  @media (max-width: 550px) {
    .navbar-brand h3{
      font-size: 18px !important;
      font-weight: 700 !important;
    }
  
    .navbar-brand img {
      max-height: 70px !important;
      
    }
  
    .navbar-nav .nav-link {
      padding-bottom: 5px;
      margin: 5px 0;
    }
  
    .navbar-nav .nav-item {
      width: 100%;
      text-align: center;
    }
  
    .dropdown-menu {
      width: 100%;
      text-align: center;
    }
 
  }
  
  